@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.tableUnitInformation tr {
  align-items: center;
  justify-content: end;
}

.tableUnitInformation tr div {
  align-items: center;
  justify-content: end;
}

.tableUnitInformation td {
  
  width: 50%;
}

.tableUnitInformation td:last-child {
  text-align: right;
  
}